@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.dropdown {
  display: flex;
  position: static;

  &.isDisabled {
    .dropdownTrigger {
      background-color: $secondary2Color;
      border-left-color: #fff;
      pointer-events: none;
    }
  }
}

.dropdownTrigger {
  background-color: $actionColor;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 30px;
  border-radius: 0 3px 3px 0;
  border-left: 1px solid #6b8ec2;
  margin-left: -3px;
  z-index: 1;

  &:hover {
    background-color: $actionColorHover;
    cursor: pointer;
  }
}

.iconWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  fill: #fff;
  transform: rotate(-90deg);
  width: 12px;

  &.isOpen {
    transform: rotate(90deg);
  }
}

.content {
  top: 41px !important;
  left: 0 !important;
  padding: 8px 0;
  transform: none !important;

  @include mqMaxWidth($screenS) {
    top: auto !important;
    bottom: 41px !important;
  }
}

.dropdownItem {
  padding: 8px 20px;
  cursor: pointer;
  color: $actionColor;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  display: block;
  border: 0;

  &:hover {
    background-color: #f9f9f9;
  }

  &:first-child {
    margin-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $defaultTableBorderColor;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
    }

    + div {
      margin-top: 15px;
    }
  }

  &.isDisabled {
    pointer-events: none;
    text-align: left;
    padding: 8px 20px;
    font-weight: 300;
    color: $secondary2Color !important;
    line-height: 20px;
    border: 0;
    display: block;
  }

  @include mqMaxWidth($screenS) {
    text-align: left !important;
  }
}
