@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

// Colors
$errorBg: $cancelColor;
$successBg: $successColor;
$infoBg: $titleColor;
$warningBg: #e8d000;

$opacityHidden: 0;
$opacityVisible: 0.9;
$opacityFocus: 1;

@mixin notification-transition {
  &-enter {
    opacity: $opacityHidden;
    transform: translate3d(0, -100%, 0);

    &-active {
      opacity: $opacityVisible;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s;
    }
  }

  &-exit {
    opacity: $opacityVisible;
    transform: translate3d(0, 0, 0);

    &-active {
      opacity: $opacityHidden;
      transform: translate3d(0, -100%, 0);
      transition: all 0.2s;
    }
  }
}

.container {
  position: fixed;
  width: auto;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 65000;
}

.wrp {
  text-align: center;
  width: 100%;
  margin-bottom: 8px;
}

.notification {
  padding: 8px 15px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  position: relative;
  opacity: $opacityVisible;
  width: auto;
  max-width: 600px;
  display: inline-block;
  z-index: 10000;
  text-align: center;

  a {
    text-decoration: underline;
    color: #fff !important;
  }

  &:hover,
  &:focus {
    opacity: $opacityFocus;
  }

  @include notification-transition;
}

.title {
  font-size: 14px;
  letter-spacing: 0.02em;
  font-weight: 500;
  margin: 0 0 4px;
  text-align: center;
}

.message {
  display: block;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: center;
}

.info {
  background-color: $infoBg;
}

.success {
  background-color: $successBg;
}

.warning {
  background-color: $warningBg;
  color: $titleColor;

  a {
    color: $titleColor !important;
  }
}

.error {
  background-color: $errorBg;
}

.icon {
  fill: #fff;
  margin-right: 10px;
  height: 12px;
  width: 12px;
  vertical-align: -1px;
}
